<template>
  <div class="setting-item s-add-more-document">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
  </div>
</template>

<script>
export default {
  name: 'SAddMoreDocument',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
